//GLOBAL ENGLISH

const englishTranslations = {
  navbar: {
    navItems: {
      about: "About Us",
      features: "Features",
      faq: "FAQs",
    },
    ctaButton: "Get The App",
  },
  mainHeading: "Manage Your Business",
  coloredMainHeading: "Finances Easily",
  heroPara:
    "Take control of your business expenses, track income, and optimize your financial health with our simple yet powerful mobile app. Perfect for small business owners, freelancers, and entrepreneurs looking to streamline financial management",
  download: "Download on the",
  appleStore: "Apple Store",
  playStore: "Google Play",
  rating: "5 star rating from over 3k+ users",
  feature1Head1: "Your ",
  feature1Head2: "Fast-Track ",
  feature1Head3: "To Financial ",
  feature1Head4: "Success",
  feature1Para:
    "FinFast is designed to simplify financial management for small businesses and freelancers. With real-time expense tracking, automated invoicing, and smart budgeting tools, we help you manage your finances quickly and easily—so you can focus on growing your business.",
  feature2Head1: "Payments Simplified In ",
  feature2Head2: "Just A Few Clicks ",
  feature2Para:
    "FinFast is designed to simplify financial management for small businesses and freelancers. With real-time expense tracking, automated invoicing, and smart budgeting tools, we help you manage your finances quickly and easily—so you can focus on growing your business.",
  feature3Head1: "Profit ",
  feature3Head2: "Smarter With ",
  feature3Head3: "AI-Driven Insights ",
  feature3Para:
    "FinFast is designed to simplify financial management for small businesses and freelancers. With real-time expense tracking, automated invoicing, and smart budgeting tools, we help you manage your finances quickly and easily—so you can focus on growing your business.",
  easyFinance: "Easy Finance Solutions",
  cardsTitle: "Cards",
  cardsDescription: "Get any number of international bank cards",
  insightsTitle: "Insights",
  insightsDescription: "View not only history but forecasted insights",
  withdrawTitle: "Withdraw",
  withdrawDescription: "Withdraw money easily to any source with low charges",
  paymentsTitle: "Payments",
  paymentsDescription: "Make payments easily using the wallet app",
  easyFinance: "Finances Just Got Easier",

  faq: {
    sectionTitle: "Frequently Asked Questions",
    items: [
      {
        title: "What is Lorem Ipsum?",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      },
      {
        title: "What is Lorem Ipsum?",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      },
      {
        title: "What is Lorem Ipsum?",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      },
      {
        title: "What is Lorem Ipsum?",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      },
    ],
  },

  easyPlatformHead: "Platform To Make It",
  easyPlatformHeadColored: "Easier",
  easyPlatformHeadRemain: "For Users",
  easyPlatformDescription:
    "Simplify payments with FinFast’s seamless platform. Request money easily by sending a secure payment link or integrating directly with your online store, giving your customers flexible and fast ways to pay",
  easyBox1Head: "Checkout",
  easyBox1Para: "Accept payments by sending a link",
  easyBox2Head: "Integration",
  easyBox2Para: "Accept payments by store integrations",
  ctaHead: "Ready To Get Started?",
  ctaPara: "Get the free Finfast app. Available on both iOS and Android",

  footerText:
    "Take control of your business expenses, track income, and optimize your financial health with our simple yet powerful mobile app",
  // Footer Links Section
  aboutFooter: "About",
  aboutUsF: "About Us",
  featuresF: "Features",
  faqF: "FAQ",

  company: "Company",
  ourTeam: "Our Team",
  partnerProgram: "Partner Program",
  careers: "Careers",
  blog: "Blog",

  contactUs: "Contact Us",
  address: "4140 Parker Rd Allentown",
  location: "New Mexico 31143",
  phone: "(480) 555-0103",
  email: "support@finfast.com",
  copyRightLine: "Copyright © 2024 Finfast | Powered By ",
  poweredBy: "DIJINX",
};

export default englishTranslations;
