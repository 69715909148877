import React, { useEffect, useState } from "react";
import useWindowSize from "../../../hooks/use-window-size";
import NavbarSmall from "../navbar/navbar-small";
import NavbarLarge from "../navbar/navbar-large";
import { useTheme } from "../../../providers/theme-provider";
import { useTranslation } from "react-i18next";
import { fontSizes } from "../../../theme/font-sizes";
import { assetIcons, assetImages } from "../../../constants/assets";
import spacings from "../../../theme/spacings";
import { delay, motion } from "framer-motion";
import InlineSvg from "../../inline-svg/inline-svg";
import { svgColors } from "../../../theme/svg-colors";
import { Link } from "react-router-dom";
import { urls } from "../../../constants/urls";

const Hero = () => {
  const { width } = useWindowSize();
  const isMobile = width < 1024;

  const { colors } = useTheme();

  const { t } = useTranslation();

  const paddingLeftRight = isMobile
    ? spacings.leftRightSpacingMobile
    : spacings.leftRightSpacing;

  // State to track scroll position
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 150);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Animation Variants
  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5, ease: "easeOut" },
    },
  };

  const staggerContainer = {
    visible: { transition: { staggerChildren: 0.15 } },
  };

  const fadeInVariantPad = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { delay: 0, duration: 1, ease: "easeOut" },
    },
  };
  const fadeInVariant = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { delay: 1, duration: 1, ease: "easeOut" },
    },
  };

  const slideInVariant = {
    hidden: { x: 100, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      transition: { duration: 1, ease: "easeOut" },
    },
  };

  return (
    <motion.div
      className="xl:min-h-screen relative"
      style={{
        backgroundColor: colors.heroBg,
        paddingLeft: paddingLeftRight,
        paddingRight: paddingLeftRight,
      }}
      initial="hidden"
      animate="visible"
      variants={staggerContainer}
      id="home"
    >
      {isMobile ? (
        <NavbarSmall isScrolled={isScrolled} />
      ) : (
        <NavbarLarge isScrolled={isScrolled} />
      )}
      <div className="grid lg:grid-cols-2 grid-cols-1 sm:gap-6 gap-3 ">
        <motion.div
          className="lg:order-1 order-2 flex flex-col min-[540px]:gap-6 gap-4 xl:py-16 lg:pt-6 pb-12 lg:max-w-2xl max-w-full"
          initial="hidden"
          animate="visible"
          // variants={staggerContainer}
          variants={fadeInUp}
        >
          <motion.div className="">
            <h3
              className="font-bold"
              style={{
                fontSize: fontSizes.heading1,
                color: colors.textColor,
              }}
            >
              {t("mainHeading")}{" "}
              <motion.span
                className="font-bold"
                style={{
                  fontSize: fontSizes.heading1,
                  // color: colors.redColor,
                }}
                initial={{ color: "#000" }}
                whileInView={{ color: ["#000", colors.redColor] }}
                transition={{ duration: 0.8, ease: "linear" }}
              >
                {t("coloredMainHeading")}
              </motion.span>
            </h3>
          </motion.div>

          <motion.div>
            <p
              style={{
                color: colors.textColor,
                fontSize: fontSizes.body,
              }}
            >
              {t("heroPara")}
            </p>
          </motion.div>

          <motion.div
            className="flex flex-row sm:gap-6 gap-4 items-center md:order-3 order-4"
            // variants={fadeInUp}
          >
            <Link to={urls.dijinx}>
              <motion.button
                className="flex flex-row sm:gap-5 gap-3 xl:py-3 sm:py-2 py-1 xl:px-6 sm:px-4 px-3 items-center "
                style={{
                  backgroundColor: colors.appBox,
                  boxShadow: "0px 2px 4px -2px #0000001A",
                  boxShadow: "0px 4px 6px -1px #0000001A",
                }}
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3, ease: "easeInOut" }}
              >
                <div>
                  <span className="xl:w-8 sm:w-6 w-5">
                    <InlineSvg
                      url={assetIcons.appleLogo}
                      color={svgColors.appleLogo}
                      className=""
                    />
                  </span>
                </div>

                <div className="flex flex-col xl:gap-1 gap-[2px]">
                  <p
                    style={{
                      color: colors.download,
                      fontSize: fontSizes.info,
                    }}
                  >
                    {t("download")}
                  </p>
                  <h3
                    style={{
                      color: colors.appleGoogle,
                      fontSize: fontSizes.heading3,
                    }}
                    className="font-semibold"
                  >
                    {t("appleStore")}
                  </h3>
                </div>
              </motion.button>
            </Link>

            <Link to={urls.dijinx}>
              <motion.button
                className="flex flex-row sm:gap-5 gap-3 xl:py-3 sm:py-2 py-1 xl:px-6 sm:px-4 px-3 items-center"
                style={{
                  backgroundColor: colors.appBox,
                  boxShadow: "0px 2px 4px -2px #0000001A",
                  boxShadow: "0px 4px 6px -1px #0000001A",
                }}
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3, ease: "easeInOut" }}
              >
                <div>
                  <img
                    src={assetIcons.googlePlayLogo}
                    alt=""
                    className="xl:w-7 sm:w-6 w-5"
                  />
                </div>

                <div className="flex flex-col xl:gap-1 gap-[2px]">
                  <p
                    style={{
                      color: colors.download,
                      fontSize: fontSizes.info,
                    }}
                  >
                    {t("download")}
                  </p>
                  <h3
                    style={{
                      color: colors.appleGoogle,
                      fontSize: fontSizes.heading3,
                    }}
                    className="font-semibold"
                  >
                    {t("playStore")}
                  </h3>
                </div>
              </motion.button>
            </Link>
          </motion.div>

          <motion.div
            className="flex flex-row gap-6 items-center md:order-4 order-3"
            // variants={fadeInUp}
          >
            <div className="relative flex -space-x-5">
              {[
                assetImages.user1,
                assetImages.user2,
                assetImages.user3,
                assetImages.user4,
                assetImages.user5,
              ].map((img, index) => (
                <img
                  key={index}
                  src={img}
                  alt=""
                  className="xl:w-14 sm:w-12 w-10 xl:h-14 sm:h-12 h-10 rounded-full border-2"
                  style={{ borderColor: colors.redColor }}
                />
              ))}
            </div>

            <div className="flex flex-col gap-1">
              <div className="flex flex-row xl:gap-3 sm:gap-2 gap-1">
                {[...Array(5)].map((_, index) => (
                  // <img
                  //   key={index}
                  //   src={assetIcons.star}
                  //   alt=""
                  //
                  // />
                  <span className="xl:w-8 sm:w-6 w-5">
                    <InlineSvg
                      url={assetIcons.star}
                      color={svgColors.starColor}
                    />
                  </span>
                ))}
              </div>
              <div>
                <p
                  style={{
                    fontSize: fontSizes.info,
                  }}
                >
                  {t("rating")}
                </p>
              </div>
            </div>
          </motion.div>
        </motion.div>

        <motion.div
          className="lg:order-2 order-1 lg:pt-20 sm:pt-16 pt-8"
          initial="hidden"
          animate="visible"
          variants={{
            visible: { transition: { staggerChildren: 0.4 } }, // Adjusted for a more staggered effect
          }}
        >
          <div className=" flex flex-col lg:justify-end justify-center lg:items-start items-center lg:static relative ">
            <motion.img
              src={assetImages.appHero}
              alt="appHero"
              className="min-[1400px]:w-[450px] xl:w-[450px] lg:w-[380px] sm:w-[330px] min-[540px]:w-[240px] w-[200px] object-contain lg:absolute lg:bottom-0 xl:right-[4%] lg:right-[2%] z-10 relative flex lg:justify-end justify-center min-[540px]:ml-32 ml-24"
              variants={slideInVariant}
            />
            <motion.img
              src={assetIcons.pad}
              alt="padImg"
              className="absolute lg:right-[6%] right-[50%] lg:left-auto left-[50%] lg:translate-x-0 translate-x-[-50%] min-[540px]:bottom-[12%] bottom-[7%] z-[2] xl:w-[520px] sm:w-[400px] min-[540px]:w-[300px] w-[260px]"
              variants={fadeInVariantPad}
            />

            <motion.img
              src={assetIcons.doodle}
              alt="doodleImg"
              className="absolute min-[1400px]:right-[10%] lg:right-[10%] right-[50%] lg:left-auto left-[50%] lg:translate-x-0 translate-x-[-50%]  sm:bottom-[10%] bottom-[14%] min-[1400px]:w-[480px] xl:w-[480px] lg:w-[400px] sm:w-[360px] min-[540px]:w-[250px] w-[220px] z-[5]"
              variants={fadeInVariant}
            />
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Hero;
