const spacings = {
  topBottomSpacing: 60,
  topBottomSpacingMobile: 40,
  topBottomSpacingMobileCta: 130,
  leftRightSpacing: 80,
  leftRightSpacingMobile: 20,
  topBottomSpacingEasy: 40,
  topBottomSpacingCTA: 100,
};

export default spacings;
