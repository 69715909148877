import React, { useEffect, useState } from "react";

function InlineSvg({ color, url, className }) {
  const [svgContent, setSvgContent] = useState("");

  useEffect(() => {
    // Fetch the SVG content from the CDN URL
    fetch(url)
      .then((res) => res.text())
      .then((data) => {
        // Create a DOM element from the SVG string
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(data, "image/svg+xml");
        const svg = svgDoc.querySelector("svg");

        // Modify fill attribute of all paths
        const paths = svg.querySelectorAll("path");
        paths.forEach((path) => {
          const currentFill = path.getAttribute("fill");

          // If the path has a fill value, change it to the new color
          if (currentFill) {
            path.setAttribute("fill", color || currentFill); // Use dynamic color or retain original fill
          }
        });

        // Store the modified SVG content in the state
        setSvgContent(svg.outerHTML);
      })
      .catch((error) => console.error("Error fetching SVG:", error));
  }, [url, color]);

  return (
    <div
      className={className}
      // Inject SVG content as HTML
      dangerouslySetInnerHTML={{ __html: svgContent }}
      // style={{ fill: svgColors[color] }}
    />
  );
}

export default InlineSvg;
