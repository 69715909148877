import React, { useState } from "react";
import spacings from "../../theme/spacings";
import { assetImages } from "../../constants/assets";
import { useTranslation } from "react-i18next";
import { fontSizes } from "../../theme/font-sizes";
import { useTheme } from "../../providers/theme-provider";
import useWindowSize from "../../hooks/use-window-size";
import { motion, useAnimation, useInView } from "framer-motion";

const FeatureSection = () => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { width } = useWindowSize();

  const isTablet = width < 1024;

  // Track image position during mouse move
  const [imagePosition, setImagePosition] = useState({ x: 0, y: 0 });

  const paddingLeftRight = isTablet
    ? spacings.leftRightSpacingMobile
    : spacings.leftRightSpacing;
  const paddingTopBottom = isTablet
    ? spacings.topBottomSpacingMobile
    : spacings.topBottomSpacing;

  // Animation variants
  const textVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 1, ease: "easeOut" },
    },
  };

  const imageVariants = {
    hidden: { opacity: 0, x: 100 },
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.8, ease: "easeOut" },
    },
    hover: {
      scale: 1.05,
      transition: { duration: 0.3, ease: "easeInOut" },
    },
  };

  // // Animation variants for color change
  // const colorAnimation = {
  //   initial: { color: "#000000" }, // Initial color (black)
  //   animate: { color: "#FF0000" }, // Target color (red)
  // };

  return (
    <motion.div
      className="flex flex-col md:gap-20 gap-8 overflow-x-hidden"
      style={{
        padding: `${paddingTopBottom}px ${paddingLeftRight}px`,
      }}
      id="features"
      initial="hidden"
      animate="visible"
      variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
    >
      <div className="grid md:grid-cols-2 grid-cols-1 lg:gap-20 md:gap-12 gap-6 place-items-center">
        <motion.div
          className="md:order-1 order-2"
          variants={imageVariants}
          initial="hidden"
          // animate="visible"
          whileHover="hover"
          whileInView="visible"
          viewport={{ once: true, amount: 0.3 }}
        >
          <motion.img
            src={assetImages.about1}
            alt="about1"
            className=""
            variants={imageVariants}
          />
        </motion.div>

        <motion.div
          className="flex flex-col gap-6 md:order-2 order-1"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.3 }}
          variants={{ visible: { transition: { staggerChildren: 0.2 } } }}
        >
          <motion.div variants={textVariants}>
            <h3
              style={{
                fontSize: fontSizes.heading1,
              }}
              className="font-bold leading-tight"
            >
              {t("feature1Head1")}{" "}
              <motion.span
                // style={{
                //   color: colors.redColor,
                // }}
                initial={{ color: "#000" }}
                whileInView={{ color: ["#000", colors.redColor] }}
                transition={{ duration: 0.8, ease: "linear" }}
              >
                {t("feature1Head2")}
              </motion.span>
              <span>{t("feature1Head3")}</span>
              <motion.span
                // style={{
                //   color: colors.redColor,
                // }}
                initial={{ color: "#000" }}
                whileInView={{ color: ["#000", colors.redColor] }}
                transition={{ duration: 0.8, ease: "linear" }}
              >
                {t("feature1Head4")}
              </motion.span>
            </h3>
          </motion.div>

          <motion.div variants={textVariants}>
            <p>{t("feature1Para")}</p>
          </motion.div>
        </motion.div>
      </div>
      <div className="grid md:grid-cols-2 grid-cols-1 lg:gap-20 md:gap-12 gap-6 place-items-center">
        <motion.div
          className="md:order-2 order-2"
          variants={imageVariants}
          initial="hidden"
          // animate="visible"
          whileHover="hover"
          whileInView="visible"
          viewport={{ once: true, amount: 0.3 }}
        >
          <motion.img
            src={assetImages.about2}
            alt="about1"
            className=""
            variants={imageVariants}
          />
        </motion.div>

        <motion.div
          className="flex flex-col gap-6 md:order-1 order-1"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.3 }}
          variants={{ visible: { transition: { staggerChildren: 0.2 } } }}
        >
          <motion.div variants={textVariants}>
            <h3
              style={{
                fontSize: fontSizes.heading1,
              }}
              className="font-bold leading-tight"
            >
              {t("feature2Head1")}{" "}
              <motion.span
                initial={{ color: "#000" }}
                whileInView={{ color: ["#000", colors.redColor] }}
                transition={{ duration: 0.8, ease: "linear" }}
              >
                {t("feature2Head2")}
              </motion.span>
            </h3>
          </motion.div>

          <motion.div variants={textVariants}>
            <p>{t("feature2Para")}</p>
          </motion.div>
        </motion.div>
      </div>
      <div className="grid md:grid-cols-2 grid-cols-1 lg:gap-20 md:gap-12 gap-6 place-items-center">
        <motion.div
          className="md:order-1 order-2"
          variants={imageVariants}
          initial="hidden"
          // animate="visible"
          whileHover="hover"
          whileInView="visible"
          viewport={{ once: true, amount: 0.3 }}
        >
          <motion.img
            src={assetImages.about3}
            alt="about1"
            className=""
            variants={imageVariants}
          />
        </motion.div>

        <motion.div
          className="flex flex-col gap-6 md:order-2 order-1"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.3 }}
          variants={{ visible: { transition: { staggerChildren: 0.2 } } }}
        >
          <motion.div variants={textVariants}>
            <h3
              style={{
                fontSize: fontSizes.heading1,
              }}
              className="font-bold leading-tight max-w-lg"
            >
              <motion.span
                initial={{ color: "#000" }}
                whileInView={{ color: ["#000", colors.redColor] }}
                transition={{ duration: 0.8, ease: "linear" }}
              >
                {t("feature3Head1")}
              </motion.span>
              <span>{t("feature3Head2")}</span>
              <motion.span
                initial={{ color: "#000" }}
                whileInView={{ color: ["#000", colors.redColor] }}
                transition={{ duration: 0.8, ease: "linear" }}
              >
                {t("feature3Head3")}
              </motion.span>
            </h3>
          </motion.div>

          <motion.div variants={textVariants}>
            <p>{t("feature3Para")}</p>
          </motion.div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default FeatureSection;
