// const fontSizes = {
//   heading1: "48px",
//   ctaHeading: "56px",
//   heading2: "20px",
//   body: "16px",
//   info: "13px",
//   // Add more as needed
// };

const fontSizes = {
  heading1: "clamp(30px, 3.5vw, 48px)", // Scales between 32px and 48px
  ctaHeading: "clamp(26px, 4vw, 56px)", // Scales between 40px and 56px
  heading2: "clamp(16px, 2vw, 22px)", // Scales between 16px and 20px
  heading3: "clamp(14px, 2vw, 20px)", // Scales between 16px and 20px
  cardHeading: "clamp(19px, 2vw, 22px)", // Scales between 16px and 20px
  body: "clamp(14px, 1.5vw, 18px)", // Scales between 14px and 16px
  cardBody: "clamp(16px, 1.5vw, 18px)", // Scales between 14px and 16px
  info: "clamp(11px, 1.5vw, 13px)", // Scales between 12px and 13px
  // Add more as needed
};

const smallFontSizes = {
  heading1: "34px",
  heading2: "20px",
  body: "14px",
  info: "13px",
  // Add more as needed
};

export { fontSizes, smallFontSizes };
