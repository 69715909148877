const assetIcons = {
  appleLogo: "https://d1uxhsybh3036x.cloudfront.net/app/svgs/apple-logo.svg",
  arrowRight: "https://d1uxhsybh3036x.cloudfront.net/app/svgs/arrow-right.svg",
  card: "https://d1uxhsybh3036x.cloudfront.net/app/svgs/card.svg",
  chart: "https://d1uxhsybh3036x.cloudfront.net/app/svgs/chart.svg",
  chevDown: "https://d1uxhsybh3036x.cloudfront.net/app/svgs/chev-down.svg",
  doodle: "https://d1uxhsybh3036x.cloudfront.net/app/svgs/doodle.svg",
  facebook: "https://d1uxhsybh3036x.cloudfront.net/app/svgs/facebook.svg",
  googlePlayLogo:
    "https://d1uxhsybh3036x.cloudfront.net/app/svgs/google-play-logo.svg",
  instagram: "https://d1uxhsybh3036x.cloudfront.net/app/svgs/instagram.svg",
  logo: "https://d1uxhsybh3036x.cloudfront.net/app/svgs/logo.svg",
  pad: "https://d1uxhsybh3036x.cloudfront.net/app/svgs/pad.svg",
  star: "https://d1uxhsybh3036x.cloudfront.net/app/svgs/star.svg",
  transfer: "https://d1uxhsybh3036x.cloudfront.net/app/svgs/transfer.svg",
  twitter: "https://d1uxhsybh3036x.cloudfront.net/app/svgs/twitter.svg",
  wallet: "https://d1uxhsybh3036x.cloudfront.net/app/svgs/wallet.svg",
  // Add more as needed
};

const assetImages = {
  about1: "https://d1uxhsybh3036x.cloudfront.net/app/pngs/about1.png",
  about2: "https://d1uxhsybh3036x.cloudfront.net/app/pngs/about2.png",
  about3: "https://d1uxhsybh3036x.cloudfront.net/app/pngs/about3.png",
  appHero: "https://d1uxhsybh3036x.cloudfront.net/app/pngs/app-hero.png",
  bottomCta: "https://d1uxhsybh3036x.cloudfront.net/app/pngs/bottom-cta.png",
  subHero: "https://d1uxhsybh3036x.cloudfront.net/app/pngs/sub-hero.png",
  user1: "https://d1uxhsybh3036x.cloudfront.net/app/pngs/user1.png",
  user2: "https://d1uxhsybh3036x.cloudfront.net/app/pngs/user2.png",
  user3: "https://d1uxhsybh3036x.cloudfront.net/app/pngs/user3.png",
  user4: "https://d1uxhsybh3036x.cloudfront.net/app/pngs/user4.png",
  user5: "https://d1uxhsybh3036x.cloudfront.net/app/pngs/user5.png",

  // Add more as needed
};

const assetVideos = {
  heroVideo: "<URL>",
  // Add more as needed
};

export { assetIcons, assetImages, assetVideos };
