import React from "react";
import { assetIcons } from "../../../constants/assets";
import { useTheme } from "../../../providers/theme-provider";
import CtaButton from "../../buttons/cta-button/cta-button";
import { motion } from "framer-motion";
import InlineSvg from "../../inline-svg/inline-svg";
import { svgColors } from "../../../theme/svg-colors";
import useSmoothScroll from "../../../hooks/use-smooth-scroll";

const NavbarSmall = ({ isScrolled }) => {
  const handleScroll = useSmoothScroll();
  const { colors } = useTheme();
  return (
    <motion.div
      className={`flex justify-between items-center gap-2 w-full transition-all duration-300 ${
        isScrolled
          ? "fixed top-4 left-[2.5%] transform -translate-x-1/2 shadow-md p-4 rounded-lg z-50 opacity-90"
          : "md:py-8 py-4"
      }`}
      style={{
        backgroundColor: isScrolled ? colors.heroBg : "transparent",
        color: colors.textColor,
        maxWidth: isScrolled ? "95%" : "100%", // Adding some spacing for small screens
      }}
      initial={{ opacity: 0, y: -100 }} // Start off the top of the screen
      animate={{ opacity: 1, y: 0 }} // Slide down into view from the top
      transition={{ duration: 0.6, ease: "backInOut" }}
    >
      <div onClick={() => handleScroll("home")}>
        <InlineSvg
          url={assetIcons.logo}
          color={svgColors.mainColor}
          className="cursor-pointer"
        />
      </div>

      <div className="flex-shrink-0">
        <CtaButton label="Get The App" />
      </div>
    </motion.div>
  );
};

export default NavbarSmall;
