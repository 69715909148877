import React from "react";
import { useTheme } from "../../providers/theme-provider";
import { useTranslation } from "react-i18next";
import spacings from "../../theme/spacings";
import { fontSizes } from "../../theme/font-sizes";
import { assetIcons, assetImages } from "../../constants/assets";
import useWindowSize from "../../hooks/use-window-size";
import { motion } from "framer-motion";
import InlineSvg from "../inline-svg/inline-svg";
import { svgColors } from "../../theme/svg-colors";
import { Link } from "react-router-dom";
import { urls } from "../../constants/urls";

const CtaSection = () => {
  const { colors } = useTheme();
  const { t } = useTranslation();

  const { width } = useWindowSize();
  const isTablet = width < 1024;

  const paddingLeftRight = isTablet
    ? spacings.leftRightSpacingMobile
    : spacings.leftRightSpacing;
  const paddingTopBottom = isTablet
    ? spacings.topBottomSpacingMobileCta
    : spacings.topBottomSpacing;

  return (
    <div
      className=""
      style={{
        padding: `${paddingTopBottom}px ${paddingLeftRight}px 100px ${paddingLeftRight}px`,
      }}
      id="cta-section"
    >
      <motion.div
        className="rounded-2xl min-[540px]:px-8 px-3 xl:py-16 py-12 flex flex-col justify-end gap-6 relative lg:min-h-0 min-[540px]:min-h-[500px] min-h-[430px]"
        style={{
          backgroundColor: colors.redColor,
        }}
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true, amount: 0.3 }}
        transition={{ duration: 0.6 }}
      >
        <div className="flex flex-col lg:mt-0 mt-10 gap-4">
          <motion.h3
            className="font-bold"
            style={{
              color: colors.background,
              fontSize: fontSizes.ctaHeading,
            }}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true, amount: 0.3 }}
            transition={{ duration: 0.6 }}
          >
            {t("ctaHead")}
          </motion.h3>

          <motion.p
            style={{
              color: colors.background,
              fontSize: fontSizes.body,
            }}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true, amount: 0.3 }}
            transition={{ duration: 0.6 }}
          >
            {t("ctaPara")}
          </motion.p>

          <div className="flex flex-row sm:gap-6 gap-3 items-center ">
            <Link to={urls.dijinx}>
              <motion.button
                className="flex flex-row gap-2 sm:gap-4 py-2 sm:py-3 px-3 sm:px-3 items-center rounded-lg"
                style={{
                  backgroundColor: colors.appBox,
                  boxShadow: "0px 2px 4px -2px #0000001A",
                  boxShadow: "0px 4px 6px -1px #0000001A",
                }}
                whileHover={{
                  scale: 1.05,
                  boxShadow: "0px 8px 12px rgba(0, 0, 0, 0.2)",
                  transition: { duration: 0.3 },
                }}
              >
                <div>
                  {/* <img
                  src={assetIcons.appleLogo}
                  alt=""
                  className="xl:w-8 sm:w-6 w-5"
                /> */}
                  <span className="xl:w-8 sm:w-6 w-5">
                    <InlineSvg
                      url={assetIcons.appleLogo}
                      color={svgColors.appleLogo}
                      className=""
                    />
                  </span>
                </div>

                <div className="flex flex-col xl:gap-1 gap-[2px]">
                  <p
                    style={{
                      color: colors.download,
                      fontSize: fontSizes.info,
                    }}
                  >
                    {t("download")}
                  </p>
                  <motion.h3
                    style={{
                      color: colors.appleGoogle,
                      fontSize: fontSizes.heading3,
                    }}
                    className="font-semibold"
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ duration: 0.6 }}
                  >
                    {t("appleStore")}
                  </motion.h3>
                </div>
              </motion.button>
            </Link>

            <Link to={urls.dijinx}>
              <motion.button
                className="flex flex-row gap-2 sm:gap-4 py-2 sm:py-3 px-3 sm:px-3 items-center rounded-lg"
                style={{
                  backgroundColor: colors.appBox,
                  boxShadow: "0px 2px 4px -2px #0000001A",
                  boxShadow: "0px 4px 6px -1px #0000001A",
                }}
                whileHover={{
                  scale: 1.05,
                  boxShadow: "0px 8px 12px rgba(0, 0, 0, 0.2)",
                  transition: { duration: 0.3 },
                }}
              >
                <div>
                  <img
                    src={assetIcons.googlePlayLogo}
                    alt=""
                    className="xl:w-7 sm:w-6 w-5"
                  />
                </div>

                <div className="flex flex-col xl:gap-1 gap-[2px]">
                  <p
                    style={{
                      color: colors.download,
                      fontSize: fontSizes.info,
                    }}
                  >
                    {t("download")}
                  </p>
                  <motion.h3
                    style={{
                      color: colors.appleGoogle,
                      fontSize: fontSizes.heading3,
                    }}
                    className="font-semibold"
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ duration: 0.6 }}
                  >
                    {t("playStore")}
                  </motion.h3>
                </div>
              </motion.button>
            </Link>
          </div>
        </div>

        <div className="absolute lg:left-auto lg:right-0 left-1/2 transform lg:translate-x-0 -translate-x-1/2 xl:-top-[25%] lg:-top-[18%] min-[540px]:-top-[28%] -top-[20%] xl:w-[540px] lg:w-[400px] min-[540px]:w-[430px] w-[280px]">
          <motion.img
            src={assetImages.bottomCta}
            alt=""
            className=""
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true, amount: 0.3 }}
            transition={{ duration: 1 }}
          />
        </div>
      </motion.div>
    </div>
  );
};

export default CtaSection;
