import React, { useState } from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { assetIcons } from "../../constants/assets";
import { fontSizes } from "../../theme/font-sizes";
import spacings from "../../theme/spacings";
import { useTheme } from "../../providers/theme-provider";
import useWindowSize from "../../hooks/use-window-size";

const Faq = () => {
  const { t } = useTranslation();

  const { colors } = useTheme();
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const { width } = useWindowSize();

  const isTablet = width < 1024;

  const paddingLeftRight = isTablet
    ? spacings.leftRightSpacingMobile
    : spacings.leftRightSpacing;
  const paddingTopBottom = isTablet
    ? spacings.topBottomSpacingMobile
    : spacings.topBottomSpacing;

  return (
    <div
      className="flex flex-col gap-8"
      style={{
        padding: `0px ${paddingLeftRight}px ${paddingTopBottom}px ${paddingLeftRight}px`,
        backgroundColor: colors.background,
      }}
      id="faq"
    >
      <h3
        style={{
          fontSize: fontSizes.heading1,
          color: colors.textColor,
        }}
        className="text-center font-bold"
      >
        {t("faq.sectionTitle")}
      </h3>

      <div className="w-full max-w-full mx-auto flex flex-col gap-4">
        {t("faq.items", { returnObjects: true }).map((item, index) => (
          <motion.div
            key={index}
            whileInView={{ opacity: 1, y: 0 }}
            initial={{ opacity: 0, y: 20 }}
            transition={{
              duration: 0.5,
              ease: "easeInOut",
              delay: index * 0.1,
            }}
            viewport={{ once: true, amount: 0.3 }}
          >
            <motion.button
              onClick={() => toggleAccordion(index)}
              className="w-full flex justify-between items-center gap-6 transition-colors duration-300 p-5 rounded-xl"
              aria-expanded={openIndex === index}
              style={{
                backgroundColor: colors.heroBg,
              }}
              whileHover={{ scale: 1.02 }}
            >
              <span
                className="text-left font-semibold"
                style={{
                  fontSize: fontSizes.body,
                  color: colors.textColor,
                }}
              >
                {item.title}
              </span>

              <motion.img
                src={assetIcons.chevDown}
                alt=""
                className={`transition-transform duration-300 w-4 ${
                  openIndex === index ? "rotate-180" : ""
                }`}
                animate={{ rotate: openIndex === index ? 180 : 0 }}
                transition={{ duration: 0.3 }}
              />
            </motion.button>

            <motion.div
              initial={false}
              animate={{ height: openIndex === index ? "auto" : 0 }}
              transition={{ duration: 0.3, ease: "easeInOut" }}
              style={{
                overflow: "hidden",
              }}
            >
              <motion.div
                className="p-4"
                style={{
                  fontSize: fontSizes.body,
                  color: colors.textColor,
                  backgroundColor: colors.background,
                }}
                initial={{ opacity: 0 }}
                animate={{ opacity: openIndex === index ? 1 : 0 }}
                transition={{ duration: 0.3 }}
              >
                {item.content}
              </motion.div>
            </motion.div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Faq;
