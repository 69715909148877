export const lightColors = {
  primary: "#000000",
  background: "#FFFFFF",
  buttonText: "#FFFFFF",
  heroBg: "#FFECEC",
  textColor: "#0F172A",
  redColor: "#FF5555",
  appBox: "#FFF2F2",
  download: "#9CA3AF",
  appleGoogle: "#4B5563",
  financeDesc: "#8D8D8D",
  // Add more colors as needed
};

export const darkColors = {
  primary: "#FFFFFF",
  background: "#000000",
  buttonText: "#000000",
  // Add more colors as needed
};

export const getColors = (isDarkMode) =>
  isDarkMode ? darkColors : lightColors;
