const socialRedirectUrls = {
  facebook: "https://dijinx.com",
  instagram: "https://dijinx.com",
  twitter: "https://dijinx.com",
  linkedIn: "https://dijinx.com",

  // Add more as needed
};

const urls = {
  dijinx: "https://dijinx.com",

  // Add more as needed
};

export { socialRedirectUrls, urls };
