// import React from "react";
// import spacings from "../../theme/spacings";
// import { useTheme } from "../../providers/theme-provider";
// import { useTranslation } from "react-i18next";
// import { assetIcons, assetImages } from "../../constants/assets";
// import { fontSizes } from "../../theme/font-sizes";

// const EasyPlatformSmall = () => {
//   const { colors } = useTheme();
//   const { t } = useTranslation();
//   return (
//     <div
//       style={{
//         padding: `${spacings.topBottomSpacingEasy}px ${spacings.leftRightSpacingMobile}px`,
//       }}
//       className="flex flex-row justify-around items-center gap-6"
//     >
//       <div className="flex flex-col gap-6">
//         <h3
//           style={{
//             fontSize: fontSizes.heading1,
//             color: colors.textColor,
//           }}
//           className="font-bold leading-tight"
//         >
//           {t("easyPlatformHead")}{" "}
//           <span
//             style={{
//               color: colors.redColor,
//             }}
//           >
//             {t("easyPlatformHeadColored")}
//           </span>{" "}
//           <span>{t("easyPlatformHeadRemain")}</span>
//         </h3>

//         <p>{t("easyPlatformDescription")}</p>

//         <div className="flex flex-row justify-around items-stretch min-[540px]:gap-4 gap-2 md:px-6 min-[540px]:px-2">
//           <div className="flex flex-col justify-around min-[540px]:gap-6 gap-2">
//             <div
//               className="md:p-8 min-[540px]:p-6 p-3 flex flex-col min-[540px]:gap-2 gap-[4px] rounded-xl flex-shrink-0"
//               style={{
//                 backgroundColor: colors.heroBg,
//               }}
//             >
//               <h3
//                 className="font-bold"
//                 style={{
//                   fontSize: fontSizes.heading2,
//                 }}
//               >
//                 {t("easyBox1Head")}
//               </h3>
//               <p
//                 style={{
//                   color: colors.financeDesc,
//                   fontSize: fontSizes.body,
//                 }}
//                 className="max-w-[200px]"
//               >
//                 {t("easyBox1Para")}
//               </p>
//               <img
//                 src={assetIcons.arrowRight}
//                 alt="arrow-right"
//                 className="min-[540px]:w-6 w-5"
//               />
//             </div>
//             <div
//               className="md:p-8 min-[540px]:p-6 p-3 flex flex-col min-[540px]:gap-2 gap-[4px] rounded-xl flex-shrink-0"
//               style={{
//                 backgroundColor: colors.heroBg,
//               }}
//             >
//               <h3
//                 className="font-bold"
//                 style={{
//                   fontSize: fontSizes.heading2,
//                 }}
//               >
//                 {t("easyBox2Head")}
//               </h3>
//               <p
//                 style={{
//                   color: colors.financeDesc,
//                   fontSize: fontSizes.body,
//                 }}
//                 className="max-w-[200px]"
//               >
//                 {t("easyBox2Para")}
//               </p>
//               <img
//                 src={assetIcons.arrowRight}
//                 alt="arrow-right"
//                 className="min-[540px]:w-6 w-5"
//               />
//             </div>
//           </div>

//           <img
//             src={assetImages.subHero}
//             alt=""
//             className="md:w-[260px] min-[540px]:w-[240px] w-[160px] object-contain"
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default EasyPlatformSmall;

import React from "react";
import spacings from "../../theme/spacings";
import { useTheme } from "../../providers/theme-provider";
import { useTranslation } from "react-i18next";
import { assetIcons, assetImages } from "../../constants/assets";
import { fontSizes } from "../../theme/font-sizes";
import { motion } from "framer-motion";

const EasyPlatformSmall = () => {
  const { colors } = useTheme();
  const { t } = useTranslation();

  return (
    <div
      style={{
        padding: `${spacings.topBottomSpacingEasy}px ${spacings.leftRightSpacingMobile}px`,
      }}
      className="flex flex-row justify-around items-center gap-6"
    >
      <div className="flex flex-col gap-6">
        <motion.h3
          style={{
            fontSize: fontSizes.heading1,
            color: colors.textColor,
          }}
          className="font-bold leading-tight"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.6 }}
        >
          {t("easyPlatformHead")}{" "}
          <motion.span
            initial={{ color: "#000" }}
            whileInView={{ color: ["#000", colors.redColor] }}
            transition={{ duration: 0.8, ease: "linear" }}
          >
            {t("easyPlatformHeadColored")}
          </motion.span>{" "}
          <span>{t("easyPlatformHeadRemain")}</span>
        </motion.h3>

        <motion.p
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.6 }}
        >
          {t("easyPlatformDescription")}
        </motion.p>

        <div className="flex flex-row justify-around items-stretch min-[540px]:gap-4 gap-2 md:px-6 min-[540px]:px-2">
          <div className="flex flex-col justify-around min-[540px]:gap-6 gap-2">
            <motion.div
              className="md:p-8 min-[540px]:p-6 p-3 flex flex-col min-[540px]:gap-2 gap-[4px] rounded-xl flex-shrink-0"
              style={{
                backgroundColor: colors.heroBg,
              }}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              whileHover={{
                scale: 1.05,
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                transition: { duration: 0.3 },
              }}
            >
              <motion.h3
                className="font-bold"
                style={{
                  fontSize: fontSizes.heading2,
                }}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.6 }}
              >
                {t("easyBox1Head")}
              </motion.h3>
              <motion.p
                style={{
                  color: colors.financeDesc,
                  fontSize: fontSizes.body,
                }}
                className="max-w-[200px]"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.6 }}
              >
                {t("easyBox1Para")}
              </motion.p>
              <motion.img
                src={assetIcons.arrowRight}
                alt="arrow-right"
                className="min-[540px]:w-6 w-5"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.6 }}
              />
            </motion.div>

            <motion.div
              className="md:p-8 min-[540px]:p-6 p-3 flex flex-col min-[540px]:gap-2 gap-[4px] rounded-xl flex-shrink-0"
              style={{
                backgroundColor: colors.heroBg,
              }}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              whileHover={{
                scale: 1.05,
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                transition: { duration: 0.3 },
              }}
            >
              <motion.h3
                className="font-bold"
                style={{
                  fontSize: fontSizes.heading2,
                }}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.6 }}
              >
                {t("easyBox2Head")}
              </motion.h3>
              <motion.p
                style={{
                  color: colors.financeDesc,
                  fontSize: fontSizes.body,
                }}
                className="max-w-[200px]"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.6 }}
              >
                {t("easyBox2Para")}
              </motion.p>
              <motion.img
                src={assetIcons.arrowRight}
                alt="arrow-right"
                className="min-[540px]:w-6 w-5"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.6 }}
              />
            </motion.div>
          </div>

          <motion.img
            src={assetImages.subHero}
            alt=""
            className="md:w-[260px] min-[540px]:w-[240px] w-[160px] object-contain"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.6 }}
          />
        </div>
      </div>
    </div>
  );
};

export default EasyPlatformSmall;
