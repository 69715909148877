import React from "react";
import { assetIcons } from "../../../constants/assets";
import { useTheme } from "../../../providers/theme-provider";
import CtaButton from "../../buttons/cta-button/cta-button";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import useSmoothScroll from "../../../hooks/use-smooth-scroll";
import InlineSvg from "../../inline-svg/inline-svg";
import { svgColors } from "../../../theme/svg-colors";

const NavbarLarge = ({ isScrolled }) => {
  const { colors } = useTheme();

  const { t } = useTranslation();

  const handleScroll = useSmoothScroll();

  // const navItemVariants = {
  //   hidden: { opacity: 0, y: -20 },
  //   visible: {
  //     opacity: 1,
  //     y: 0,
  //     transition: { duration: 0.6, ease: "linear" },
  //   },
  // };

  const navItems = [
    { label: t("navbar.navItems.about"), sectionId: "about" },
    { label: t("navbar.navItems.features"), sectionId: "features" },
    { label: t("navbar.navItems.faq"), sectionId: "faq" },
  ];

  return (
    <motion.div
      className={`flex justify-between items-center gap-2 w-full transition-all duration-300 ${
        isScrolled
          ? "fixed top-4 left-[2.5%] shadow-md p-3 rounded-lg z-50"
          : "md:py-8 py-4"
      }`}
      style={{
        backgroundColor: isScrolled ? colors.heroBg : "transparent",
        color: colors.textColor,
        maxWidth: isScrolled ? "95%" : "100%",
      }}
      initial={{ opacity: 0, y: isScrolled ? -100 : 0 }} // Start off the top of the screen
      animate={{ opacity: 1, y: isScrolled ? 0 : 0 }} // Slide down into view from the top
      transition={{ duration: 0.6, ease: "backInOut" }}
    >
      <div onClick={() => handleScroll("home")}>
        {/* <InlineSvg
          url={assetIcons.logo}
          color={svgColors.mainColor}
          className="cursor-pointer"
        /> */}

        <img src={assetIcons.logo} alt="logo" className="cursor-pointer" />
      </div>

      <nav>
        <motion.ul
          className="flex gap-8 items-center"
          initial="hidden"
          animate="visible"
          variants={{
            visible: { transition: { staggerChildren: 0.25 } },
          }}
        >
          {navItems.map((item, index) => (
            <motion.li
              key={index}
              // variants={navItemVariants}
              className="font-semibold cursor-pointer relative group"
              style={{
                color: colors.textColor,
                transition: "color 0.1s ease",
              }}
              onClick={() => handleScroll(item.sectionId)}
              whileHover={{
                color: colors.redColor, // Change text color on hover
                scale: 1.05,
              }}
            >
              {item.label}
              <motion.div
                className="absolute left-0 bottom-0 h-[2px] w-full bg-current scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left"
                style={{ backgroundColor: colors.redColor }}
              />
            </motion.li>
          ))}
        </motion.ul>
      </nav>

      <motion.div
        whileHover={{ scale: 1.05 }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
      >
        <CtaButton
          label={t("navbar.ctaButton")}
          onClick={() => handleScroll("cta-section")}
        />
      </motion.div>
    </motion.div>
  );
};

export default NavbarLarge;
