import React from "react";
import { useTheme } from "../../providers/theme-provider";
import { useTranslation } from "react-i18next";
import { assetIcons } from "../../constants/assets";
import { fontSizes } from "../../theme/font-sizes";
import { Link } from "react-router-dom";
import { socialRedirectUrls, urls } from "../../constants/urls";
import useWindowSize from "../../hooks/use-window-size";
import { motion } from "framer-motion";
import spacings from "../../theme/spacings";
import useSmoothScroll from "../../hooks/use-smooth-scroll";
import { svgColors } from "../../theme/svg-colors";
import InlineSvg from "../inline-svg/inline-svg";

const FooterLarge = () => {
  const { colors } = useTheme();
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const handleScroll = useSmoothScroll();

  const isTablet = width < 1024;

  const paddingLeftRight = isTablet
    ? spacings.leftRightSpacingMobile
    : spacings.leftRightSpacing;
  const paddingTopBottom = isTablet
    ? spacings.topBottomSpacingMobile
    : spacings.topBottomSpacing;

  return (
    <div style={{ backgroundColor: colors.heroBg }}>
      <motion.div
        style={{ padding: `${paddingTopBottom}px ${paddingLeftRight}px` }}
        className="flex flex-col lg:flex-row justify-between items-start gap-8 sm:gap-10 lg:gap-10"
        initial={{ opacity: 0, y: 20 }} // Initial state for footer
        whileInView={{ opacity: 1, y: 0 }} // Animate to visible when in view
        transition={{ duration: 0.5, ease: "easeInOut" }} // Transition settings
        viewport={{ once: true, amount: 0.3 }}
      >
        {/* Logo & Social Icons */}
        <motion.div
          initial={{ opacity: 0, y: 20 }} // Initial state for first column
          whileInView={{ opacity: 1, y: 0 }} // Animate to visible when in view
          transition={{ duration: 0.5, ease: "easeInOut", delay: 0.1 }} // Delay for staggered effect
          viewport={{ once: true, amount: 0.3 }}
          className="flex flex-col gap-6 lg:gap-8 w-full"
        >
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.5 }}
            className="flex flex-row justify-between items-start lg:max-w-[475px] gap-4 lg:gap-0"
          >
            <div>
              <div onClick={() => handleScroll("home")}>
                <InlineSvg
                  url={assetIcons.logo}
                  color={svgColors.mainColor}
                  className="cursor-pointer"
                />
              </div>
            </div>
            <div className="flex gap-3">
              <Link to={socialRedirectUrls.facebook}>
                <motion.div
                  className="h-10 w-10 flex justify-center items-center rounded-lg cursor-pointer"
                  style={{ backgroundColor: colors.background }}
                  initial={{ opacity: 0, x: -10 }}
                  animate={{ opacity: 1, x: 0 }}
                  whileHover={{ scale: 1.1, duration: 0.3 }}
                  transition={{ duration: 0.5 }}
                >
                  <InlineSvg
                    url={assetIcons.facebook}
                    color={svgColors.appleLogo}
                    className="flex-shrink-0"
                  />
                </motion.div>
              </Link>
              <Link to={socialRedirectUrls.twitter}>
                <motion.div
                  className="h-10 w-10 flex justify-center items-center rounded-lg cursor-pointer"
                  style={{ backgroundColor: colors.background }}
                  initial={{ opacity: 0, x: -10 }}
                  animate={{ opacity: 1, x: 0 }}
                  whileHover={{ scale: 1.1, duration: 0.3 }}
                  transition={{ duration: 0.5 }}
                >
                  <InlineSvg
                    url={assetIcons.twitter}
                    color={svgColors.appleLogo}
                    className="flex-shrink-0"
                  />
                </motion.div>
              </Link>
              <Link to={socialRedirectUrls.instagram}>
                <motion.div
                  className="h-10 w-10 flex justify-center items-center rounded-lg cursor-pointer"
                  style={{ backgroundColor: colors.background }}
                  initial={{ opacity: 0, x: -10 }}
                  animate={{ opacity: 1, x: 0 }}
                  whileHover={{ scale: 1.1, duration: 0.3 }}
                  transition={{ duration: 0.5 }}
                >
                  <InlineSvg
                    url={assetIcons.instagram}
                    color={svgColors.appleLogo}
                    className="flex-shrink-0"
                  />
                </motion.div>
              </Link>
            </div>
          </motion.div>

          {/* Footer Text */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.6, duration: 0.5 }}
            className="lg:max-w-sm text-sm lg:text-base"
          >
            <p>{t("footerText")}</p>
          </motion.div>

          {/* Download Buttons */}
          <div className="flex flex-row sm:gap-6 gap-3 items-center ">
            <Link to={urls.dijinx}>
              <motion.button
                className="flex flex-row gap-2 sm:gap-4 py-2 sm:py-3 px-3 sm:px-3 items-center rounded-lg"
                style={{
                  backgroundColor: colors.appBox,
                  boxShadow: "0px 2px 4px -2px #0000001A",
                  boxShadow: "0px 4px 6px -1px #0000001A",
                }}
                whileHover={{
                  scale: 1.05,
                  boxShadow: "0px 8px 12px rgba(0, 0, 0, 0.2)",
                  transition: { duration: 0.3 },
                }}
              >
                <div>
                  {/* <img
                  src={assetIcons.appleLogo}
                  alt=""
                  className="xl:w-8 sm:w-6 w-5"
                /> */}
                  <span className="xl:w-8 sm:w-6 w-5">
                    <InlineSvg
                      url={assetIcons.appleLogo}
                      color={svgColors.appleLogo}
                      className=""
                    />
                  </span>
                </div>

                <div className="flex flex-col xl:gap-1 gap-[2px]">
                  <p
                    style={{
                      color: colors.download,
                      fontSize: fontSizes.info,
                    }}
                  >
                    {t("download")}
                  </p>
                  <motion.h3
                    style={{
                      color: colors.appleGoogle,
                      fontSize: fontSizes.heading3,
                    }}
                    className="font-semibold"
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ duration: 0.6 }}
                  >
                    {t("appleStore")}
                  </motion.h3>
                </div>
              </motion.button>
            </Link>

            <Link to={urls.dijinx}>
              <motion.button
                className="flex flex-row gap-2 sm:gap-4 py-2 sm:py-3 px-3 sm:px-3 items-center rounded-lg"
                style={{
                  backgroundColor: colors.appBox,
                  boxShadow: "0px 2px 4px -2px #0000001A",
                  boxShadow: "0px 4px 6px -1px #0000001A",
                }}
                whileHover={{
                  scale: 1.05,
                  boxShadow: "0px 8px 12px rgba(0, 0, 0, 0.2)",
                  transition: { duration: 0.3 },
                }}
              >
                <div>
                  <img
                    src={assetIcons.googlePlayLogo}
                    alt=""
                    className="xl:w-7 sm:w-6 w-5"
                  />
                </div>

                <div className="flex flex-col xl:gap-1 gap-[2px]">
                  <p
                    style={{
                      color: colors.download,
                      fontSize: fontSizes.info,
                    }}
                  >
                    {t("download")}
                  </p>
                  <motion.h3
                    style={{
                      color: colors.appleGoogle,
                      fontSize: fontSizes.heading3,
                    }}
                    className="font-semibold"
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ duration: 0.6 }}
                  >
                    {t("playStore")}
                  </motion.h3>
                </div>
              </motion.button>
            </Link>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, ease: "easeInOut", delay: 0.1 }}
          viewport={{ once: true, amount: 0.3 }}
          className="flex flex-col sm:flex-row justify-between w-full gap-6 lg:gap-10"
        >
          {[
            {
              title: t("aboutFooter"),
              scrollLinks: [
                { title: t("aboutUsF"), href: "about" },
                { title: t("featuresF"), href: "features" },
                { title: t("faqF"), href: "faq" },
              ],
            },
            {
              title: t("company"),
              externalLinks: [
                { title: t("ourTeam"), href: urls.dijinx },
                { title: t("partnerProgram"), href: urls.dijinx },
                { title: t("careers"), href: urls.dijinx },
                { title: t("blog"), href: urls.dijinx },
              ],
            },
            {
              title: t("contactUs"),
              externalLinks: [
                {
                  title: (
                    <>
                      <span style={{ display: "block", marginBottom: "16px" }}>
                        {t("address")}
                      </span>
                      <span>{t("location")}</span>
                    </>
                  ),
                  href: urls.dijinx,
                },
                // { title: , href: urls.dijinx },
                { title: t("phone"), href: urls.dijinx },
                { title: t("email"), href: urls.dijinx },
              ],
            },
          ].map((section, index) => (
            <motion.div
              key={index}
              className="flex flex-col gap-6"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 1.4 + index * 0.2, duration: 0.5 }}
            >
              <h3
                className="font-bold text-sm sm:text-base lg:text-lg"
                style={{ fontSize: fontSizes.heading2 }}
              >
                {section.title}
              </h3>
              <ul className="flex flex-col gap-2 sm:gap-4">
                {/* Links that use handleScroll */}
                {section.scrollLinks &&
                  section.scrollLinks.map((link, idx) => (
                    <motion.li
                      key={idx}
                      className="text-xs sm:text-sm lg:text-base cursor-pointer"
                      style={{ fontSize: fontSizes.body }}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      whileHover={{ scale: 1.05 }}
                      onClick={() => handleScroll(link.href)}
                    >
                      {link.title}
                    </motion.li>
                  ))}

                {/* Links that use <Link> */}
                {section.externalLinks &&
                  section.externalLinks.map((link, idx) => (
                    <motion.li
                      key={idx}
                      className="text-xs sm:text-sm lg:text-base"
                      style={{ fontSize: fontSizes.body }}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      whileHover={{ scale: 1.05 }}
                    >
                      <Link to={link.href}>{link.title}</Link>
                    </motion.li>
                  ))}
              </ul>
            </motion.div>
          ))}
        </motion.div>
      </motion.div>

      {/* Footer Copyright */}
      <motion.div
        initial={{ opacity: 0, y: 20 }} // Initial state for first column
        whileInView={{ opacity: 1, y: 0 }} // Animate to visible when in view
        transition={{ duration: 0.5, ease: "easeInOut", delay: 0.1 }} // Delay for staggered effect
        viewport={{ once: true, amount: 0.3 }}
        className="px-6 py-4"
      >
        <p className="text-center text-xs sm:text-sm lg:text-base">
          {t("copyRightLine")}{" "}
          <Link to={urls.dijinx} className="underline">
            {t("poweredBy")}
          </Link>
        </p>
      </motion.div>
    </div>
  );
};

export default FooterLarge;
