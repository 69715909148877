import React from "react";
import spacings from "../../theme/spacings";
import { useTheme } from "../../providers/theme-provider";
import { useTranslation } from "react-i18next";
import { assetIcons, assetImages } from "../../constants/assets";
import { fontSizes } from "../../theme/font-sizes";
import { motion } from "framer-motion";
import InlineSvg from "../inline-svg/inline-svg";
import { svgColors } from "../../theme/svg-colors";

const EasyPlatform = () => {
  const { colors } = useTheme();
  const { t } = useTranslation();
  return (
    <div
      style={{
        padding: `${spacings.topBottomSpacingEasy}px ${spacings.leftRightSpacing}px`,
      }}
      className="flex flex-row justify-around items-center gap-6"
      id="about"
    >
      <motion.img
        src={assetImages.subHero}
        alt=""
        className="xl:w-[500px] w-[370px] object-contain"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.6 }}
        viewport={{ once: true, amount: 0.3 }}
        whileHover={{
          scale: 1.05,
          transition: { duration: 0.4 },
        }}
      />

      <div className="flex flex-col gap-6">
        <motion.h3
          style={{
            fontSize: fontSizes.heading1,
            color: colors.textColor,
          }}
          className="font-bold leading-tight"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true, amount: 0.3 }}
        >
          {t("easyPlatformHead")}{" "}
          <motion.span
            initial={{ color: "#000" }}
            whileInView={{ color: ["#000", colors.redColor] }}
            viewport={{ once: true, amount: 0.3 }}
            transition={{ duration: 0.8, ease: "linear" }}
          >
            {t("easyPlatformHeadColored")}
          </motion.span>{" "}
          <span>{t("easyPlatformHeadRemain")}</span>
        </motion.h3>

        <motion.p
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true, amount: 0.3 }}
          transition={{ duration: 0.6 }}
        >
          {t("easyPlatformDescription")}
        </motion.p>

        <div className="flex flex-row gap-4">
          <motion.div
            className="xl:p-6 p-4 flex flex-col gap-2 rounded-xl flex-shrink-0"
            style={{
              backgroundColor: colors.heroBg,
            }}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            viewport={{ once: true, amount: 0.3 }}
            whileHover={{
              scale: 1.05,
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
              transition: { duration: 0.3 },
            }}
          >
            <motion.h3
              className="font-bold"
              style={{
                fontSize: fontSizes.heading2,
              }}
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.6 }}
            >
              {t("easyBox1Head")}
            </motion.h3>
            <motion.p
              style={{
                color: colors.financeDesc,
              }}
              className="xl:max-w-[200px] max-w-[180px]"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.6 }}
            >
              {t("easyBox1Para")}
            </motion.p>
            {/* <motion.img
              src={assetIcons.arrowRight}
              alt="arrow-right"
              className="w-6 cursor-pointer"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.6 }}
              whileHover={{ translateX: 20 }}
            /> */}
            <motion.span
              className="w-fit"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.6 }}
              whileHover={{ translateX: 20 }}
            >
              <InlineSvg
                url={assetIcons.arrowRight}
                color={svgColors.mainColor}
                className="cursor-pointer w-6"
              />
            </motion.span>
          </motion.div>
          <motion.div
            className="xl:p-6 p-4 flex flex-col gap-2 rounded-xl flex-shrink-0"
            style={{
              backgroundColor: colors.heroBg,
            }}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.4 }}
            viewport={{ once: true, amount: 0.3 }}
            whileHover={{
              scale: 1.05,
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
              transition: { duration: 0.3 },
            }}
          >
            <motion.h3
              className="font-bold"
              style={{
                fontSize: fontSizes.heading2,
              }}
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.6 }}
            >
              {t("easyBox2Head")}
            </motion.h3>
            <motion.p
              style={{
                color: colors.financeDesc,
              }}
              className="xl:max-w-[200px] max-w-[180px]"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.6 }}
            >
              {t("easyBox2Para")}
            </motion.p>
            {/* <motion.img
              src={assetIcons.arrowRight}
              alt="arrow-right"
              className="w-6 cursor-pointer"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.6 }}
              whileHover={{ translateX: 20 }}
            /> */}
            <motion.span
              className="w-fit"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.6 }}
              whileHover={{ translateX: 20 }}
            >
              <InlineSvg
                url={assetIcons.arrowRight}
                color={svgColors.mainColor}
                className="cursor-pointer w-6"
              />
            </motion.span>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default EasyPlatform;
