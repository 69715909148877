import React from "react";
import { fontSizes } from "../../theme/font-sizes";
import spacings from "../../theme/spacings";
import { useTranslation } from "react-i18next";
import CtaButton from "../../components/buttons/cta-button/cta-button";
import languages from "../../i18n/languages";
import { useTheme } from "../../providers/theme-provider";
import useWindowSize from "../../hooks/use-window-size";
import HeaderSmall from "../../components/header/header-small";
import HeaderLarge from "../../components/header/header-large";
import FeatureSection from "../../components/features/features-section";
import SubFeaturesSection from "../../components/sub-features/sub-features-section";
import Faq from "../../components/faq/faq";
import EasyPlatform from "../../components/easy-platform/easy-platform";
import CtaSection from "../../components/cta-section/cta-section";
import FooterLarge from "../../components/footer/footer-large";
import EasyPlatformSmall from "../../components/easy-platform/easy-platform-small";

function HomePage() {
  const { width } = useWindowSize();
  const isMobile = width < 1024;
  //------------
  // DECLARATIONS & FUNCTIONS
  //------------
  const { colors, toggleTheme } = useTheme();
  const { t, i18n } = useTranslation();

  const changeLocale = () => {
    const newLocale =
      i18n.language.toString() === languages.english
        ? languages.spanish
        : languages.english;
    i18n.changeLanguage(newLocale);
  };

  //------------
  // UI
  //------------

  return (
    <div>
      {isMobile ? <HeaderSmall /> : <HeaderLarge />}
      <FeatureSection />
      <SubFeaturesSection />
      {isMobile ? <EasyPlatformSmall /> : <EasyPlatform />}
      <Faq />
      <CtaSection />
      <FooterLarge />
    </div>
  );
}

export default HomePage;
