// // src/hooks/useSmoothScroll.js
// import { useCallback } from "react";

// const useSmoothScroll = () => {
//     const handleScroll = useCallback((sectionId) => {
//         const element = document.getElementById(sectionId);
//         if (element) {
//             element.scrollIntoView({
//                 behavior: "smooth",
//                 block: "start",
//             });
//         }
//     }, []);

//     return handleScroll;
// };

// export default useSmoothScroll;


// src/hooks/useSmoothScroll.js
import { useCallback } from "react";

const useSmoothScroll = () => {
    const handleScroll = useCallback((sectionId) => {
        const element = document.getElementById(sectionId);
        if (element) {
            const targetPosition = element.getBoundingClientRect().top + window.pageYOffset;
            const startPosition = window.pageYOffset;
            const distance = targetPosition - startPosition;
            const duration = 1500; // Set the scroll duration to 1.5 seconds for a slower scroll

            let start = null;
            const animateScroll = (currentTime) => {
                if (!start) start = currentTime;
                const timeElapsed = currentTime - start;
                const run = easeInOutQuad(timeElapsed, startPosition, distance, duration);

                window.scrollTo(0, run);
                if (timeElapsed < duration) requestAnimationFrame(animateScroll);
            };

            const easeInOutQuad = (t, b, c, d) => {
                t /= d / 2;
                if (t < 1) return c / 2 * t * t + b;
                t--;
                return -c / 2 * (t * (t - 2) - 1) + b;
            };

            requestAnimationFrame(animateScroll);
        }
    }, []);

    return handleScroll;
};

export default useSmoothScroll;
