import React from "react";
import spacings from "../../theme/spacings";
import { useTheme } from "../../providers/theme-provider";
import { useTranslation } from "react-i18next";
import { fontSizes } from "../../theme/font-sizes";
import { assetIcons } from "../../constants/assets";
import useWindowSize from "../../hooks/use-window-size";
import { motion } from "framer-motion";
import InlineSvg from "../inline-svg/inline-svg";
import { svgColors } from "../../theme/svg-colors";

const SubFeaturesSection = () => {
  const { colors } = useTheme();
  const { t } = useTranslation();

  const { width } = useWindowSize();

  const isTablet = width < 1024;

  const paddingLeftRight = isTablet
    ? spacings.leftRightSpacingMobile
    : spacings.leftRightSpacing;
  const paddingTopBottom = isTablet
    ? spacings.topBottomSpacingMobile
    : spacings.topBottomSpacing;

  const finance = [
    {
      icon: assetIcons.card,
      titleKey: t("cardsTitle"),
      descriptionKey: t("cardsDescription"),
    },
    {
      icon: assetIcons.chart,
      titleKey: t("insightsTitle"),
      descriptionKey: t("insightsDescription"),
    },
    {
      icon: assetIcons.wallet,
      titleKey: t("withdrawTitle"),
      descriptionKey: t("withdrawDescription"),
    },
    {
      icon: assetIcons.transfer,
      titleKey: t("paymentsTitle"),
      descriptionKey: t("paymentsDescription"),
    },
  ];
  return (
    <div
      style={{
        padding: `${paddingTopBottom}px ${paddingLeftRight}px`,
        backgroundColor: colors.heroBg,
      }}
      className="flex flex-col gap-12"
    >
      <div>
        <motion.h3
          className="text-center font-bold"
          style={{
            fontSize: fontSizes.heading1,
          }}
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true, amount: 0.3 }}
        >
          {t("easyFinance")}
        </motion.h3>
      </div>

      <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-6 ">
        {finance.map((fin, index) => (
          <motion.div
            className="flex flex-col gap-3 justify-center items-center p-5 rounded-xl min-h-[216px]"
            style={{
              backgroundColor: colors.background,
            }}
            key={index}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: index * 0.2 }}
            viewport={{ once: true, amount: 0.3 }}
            whileHover={{
              scale: 1.05,
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
              transition: { duration: 0.2 },
            }}
          >
            <div>
              {/* <img src={fin.icon} alt="" /> */}
              <InlineSvg url={fin.icon} color={svgColors.mainColor} />
            </div>

            <div>
              <motion.h3
                style={{
                  fontSize: fontSizes.cardHeading,
                }}
                className="font-bold"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.6 }}
              >
                {t(fin.titleKey)}
              </motion.h3>
            </div>

            <div>
              <motion.p
                className="text-center md:max-w-[180px] max-w-[220px]"
                style={{
                  color: colors.financeDesc,
                  fontSize: fontSizes.cardBody,
                }}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.6 }}
                viewport={{ once: true, amount: 0.3 }}
              >
                {t(fin.descriptionKey)}
              </motion.p>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default SubFeaturesSection;
